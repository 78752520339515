import { advertoryPlacements } from './placements';
import { hasSession } from '../auth/schibsted-account';
import { trackerInstance } from 'tracking/pulse';
import { isVGApp, getAdvertoryDevice, isDesktop } from '../utils/device';
import {
    adsToReplace,
    getFinnBlinkSizes,
    isAdBlockEnabled,
} from './fluepapir-check';
import { allowedToUseGlimr } from '../glimr/sdk';

const noSnoNewsSection = window.location.href.includes('/sport')
    ? 'vg_sport'
    : undefined;

const getUserData = async () => {
    try {
        const user = await hasSession();
        return user;
    } catch (e) {
        return {};
    }
};

const prepareAdvertoryConfig = async () => {
    const { userId, uuid } = await getUserData();
    const hasDarkMode = document.documentElement.dataset.colorScheme === 'dark';

    const advertoryConfig = {
        publisher: 'vg',
        country: 'no',
        pageType: 'front',
        deviceType: getAdvertoryDevice(),
        isMobileApp: isVGApp(),
        requestSize: 5,
        pageOptions: {
            member: 9700,
            keywords: {
                'aa-sch-inventory_type': 'editorial',
                'no-sno-publishergroup': 'schibsted',
                'aa-sch-new-wallpaper-implementation': true,
                'no-sno-news-section': noSnoNewsSection,
            },
            user: {},
        },
        astUrl: 'https://acdn.adnxs.com/ast/ast.js',
        prebid: {
            enabled: true,
            url: 'https://schibsted-cdn.relevant-digital.com/static/tags/6316fce35aea87bd62e92853.js',
            id: '6316fd0b9e0da8f244e92854',
            externalUidInAuctionEnabled: true,
            forwardGlimrTags: true,
        },
        placements: advertoryPlacements,
        appearanceConfig: {
            defaultThreshold: {
                desktop: 150,
                tablet: 150,
                mobile: 150,
            },
            isDarkModeEnabled: hasDarkMode,
        },
        queryConfig: {
            lazyLoadingV3: {
                enabled: true,
                rootMargin: '200% 0px',
            },
            prioritizeMobileFirstPlacement: true,
        },
        refreshConfig: {
            delayTime: 1000,
            scrollRefreshEnabled: true,
            visibilityStateRefreshEnabled: true,
        },
        glimr: {
            enabled: await allowedToUseGlimr(),
            clientId: 'H3IYO2A4LD43YPFZIJLN',
        },
        hashKeys: {
            userHashKey: 'SCHNO-UserHash',
            envHashKey: 'SCHNO-EnvHash',
        },
        tracker: trackerInstance,
        tcf: {
            enabled: !!window._tcf_,
        },
        wallpaper: {
            enabled: true,
            options: {
                appMainContainerSelector: '#app',
                wallpaperAnchorSelector:
                    '.advertory-vg-skyscraperleft_1__front',
            },
        },
        fullscreenScroll: {
            fadingHeaderEnabled: true,
            headerSelectors: ['vg-nav-container'],
            headerZIndex: 100,
            overlayStyles: {
                backgroundColor: 'var(--background-primary)',
                foregroundColor: 'var(--background-secondary)',
                articlesContainerWidth: 1010,
                margin: isDesktop()
                    ? '0 0 0 calc(-1*(100vw - 1010px + 30px)/2)'
                    : '0 0 0 calc(-50vw + 50%);',
            },
        },
        finnBlink: {
            enabled: true,
            channel: 'vg.no',
            userId,
            uuid,
            theme: hasDarkMode ? 'dark' : 'light',
            replacementConfig: {
                adsToReplace,
                isAdBlockEnabled: await isAdBlockEnabled(),
                sizes: getFinnBlinkSizes(getAdvertoryDevice() === 'mobile'),
            },
        },
        brandboard: {
            enabled: true,
            sidebarContainerSelector: '#hoyrelopet',
            sidebarItemsContainerSelector: '.article',
        },
    };

    return advertoryConfig;
};

export { prepareAdvertoryConfig };
